<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="显示区域">

        <template v-if="form.show_areas.length > 0">
          <el-form-item v-for="(item,index) in form.show_areas"
                        style="margin-bottom:20px"
                        :prop="'show_areas.' + index "
                        :rules="{
                           validator: showAreaRule, trigger: 'change'
                        }"
                        :key="index">
            <el-row type="flex">
              <Address style="flex:1"
                       v-model="form.show_areas[index]" />
              <el-button type="danger"
                         class="ml-15"
                         @click="delShowArea(index)">删除</el-button>
            </el-row>
          </el-form-item>

        </template>
        <div class="input-tip"
             v-else>已在全国显示</div>
        <el-button type="primary"
                   @click="addShowArea()">{{form.show_areas.length>0?'添加':'限制显示区域'}}</el-button>
      </el-form-item>
      <el-form-item label="不显示区域">

        <template v-if="form.hide_areas.length > 0">
          <el-form-item v-for="(item,index) in form.hide_areas"
                        style="margin-bottom:20px"
                        :prop="'hide_areas.' + index "
                        :rules="{
                           validator: hideAreaRule, trigger: 'change'
                        }"
                        :key="index">
            <el-row type="flex">
              <Address style="flex:1"
                       v-model="form.hide_areas[index]" />
              <el-button type="danger"
                         class="ml-15"
                         @click="delHideArea(index)">删除</el-button>
            </el-row>
          </el-form-item>

        </template>
        <div class="input-tip"
             v-else>不限制</div>
        <el-button type="primary"
                   @click="addHideArea()">{{form.hide_areas.length>0?'添加':'限制不显示区域'}}</el-button>
      </el-form-item>
      <el-form-item label="权益名称"
                    class="width300"
                    prop="name">
        <el-input type="text"
                  placeholder="请输入权益名称"
                  v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="权益图片"
                    prop="img_id">
        <ImgUpload :fileList.sync="imgList"
                   pixel="建议使用120*120像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="可领人群"
                    required>
        <el-radio-group v-model="form.threshold">
          <el-radio label="ALL">所有会员</el-radio>
          <el-radio label="CRM_MEMBER">实名会员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="权益标签">
        <el-checkbox-group v-model="form.label">
          <el-checkbox v-for="(item,index) in tabList"
                       :label="item.id"
                       :key="index">{{item.name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="派发时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        @change="dateChange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="礼品类型"
                    required>
        <el-radio-group v-model="form.type"
                        :disabled="form.id?true:false">
          <el-radio label="COUPON">代金券</el-radio>
          <el-radio label="CARD_KEY">卡密</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="关联优惠券"
                    prop="coupon_id"
                    v-if="form.type === 'COUPON'"
                    class="width300">
        <el-row>
          <el-select v-model="value"
                     filterable
                     remote
                     placeholder="请输入批次号"
                     :remote-method="remoteMethod"
                     @change="value = ''"
                     :loading="selectLoading">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
              <div @click="onClick(item)">
                {{item.id}}-{{item.coupon_stock_id}}-{{item.title}}
              </div>
            </el-option>
          </el-select>
        </el-row>
        <el-row class="mt-15"
                type="flex">
          <el-tag type="success"
                  class="mr-15"
                  closable
                  @close="close(index)"
                  v-for="(item,index) in couponList"
                  :key="index">{{item.id}}-{{item.coupon_stock_id}}-{{item.title}}</el-tag>
        </el-row>

      </el-form-item>
      <el-form-item label="卡密id"
                    v-if="form.type === 'CARD_KEY'"
                    prop="card_id"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入卡密id"
                  v-model.number="form.card_id"></el-input>
      </el-form-item>
      <el-form-item label="库存"
                    prop="total_inventory"
                    class="width300">
        <el-input type="text"
                  placeholder=""
                  v-model.number="form.total_inventory"></el-input>
      </el-form-item>
      <el-form-item label="排序"
                    prop="index"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入库存，越小越靠前"
                  v-model.number="form.index"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setEquity, getEquityDetail, getTabList } from '@/api/equity'
import { fetchVouchers } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Address from '@/components/Address'
import { getImgId } from '@/utils'
export default {
  name: 'SetEquity',
  data () {
    return {
      address: {
        province: '',
        city: '',
        district: ''
      },
      noAddress: {
        province: '',
        city: '',
        district: ''
      },
      tabList: [],
      dateRule: {},
      value: '',
      couponList: [],
      imgList: [],
      dateTime: [],
      selectLoading: false,
      areaAddress: {
        province: '',
        city: '',
        district: ''
      },
      coupons: [],
      options: [],
      form: {
        show_areas: [],
        hide_areas: [],
        province: '',
        city: '',
        district: '',
        name: '',
        threshold: 'ALL',
        img_id: 0,
        started_at: '',
        ended_at: '',
        total_inventory: 0,
        coupon_id: '',
        card_id: 0,
        money: 0,
        id: '',
        type: 'COUPON',
        index: 0,
        label: []
      },
      rules: {
        province: [
          { required: true, message: '至少选择到省', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入权益名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传权益图片', trigger: 'change' }
        ],
        coupon_id: [
          { required: true, message: '请添加代金券', trigger: 'change' }
        ],
        card_id: [
          { required: true, message: '请输入卡密id', trigger: 'blur' }
        ],

        index: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        total_inventory: [
          { required: true, message: '请输入库存', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Address,
    ImgUpload
  },
  methods: {
    addShowArea () {
      this.form.show_areas.push({
        province: '',
        city: '',
        district: ''
      })
    },
    delShowArea (index) {
      this.form.show_areas.splice(index, 1)
    },
    showAreaRule (rule, value, callback) {
      let index = 0
      if (value.province) {
        for (const k of this.form.show_areas) {
          if (k.province === value.province && k.city === value.city && k.district === value.district) {
            index++
          }
        }
        if (index >= 2) {
          callback(new Error('限制区域不能重复'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请选择省份'))
      }
    },
    addHideArea () {
      this.form.hide_areas.push({
        province: '',
        city: '',
        district: ''
      })
    },
    delHideArea (index) {
      this.form.hide_areas.splice(index, 1)
    },
    hideAreaRule (rule, value, callback) {
      let index = 0
      if (value.province) {
        for (const k of this.form.hide_areas) {
          if (k.province === value.province && k.city === value.city && k.district === value.district) {
            index++
          }
        }
        if (index >= 2) {
          callback(new Error('限制区域不能重复'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请选择省份'))
      }
    },
    async getTabList () {
      const res = await getTabList({ status: true })
      if (res.meta.code === 0) {
        this.tabList = res.data.label
      }
    },
    dateChange (e) {
      this.form.started_at = e[0]
      this.form.ended_at = e[1]
    },
    close (index) {
      this.form.coupon_id = ''
      this.couponList.splice(index, 1)
    },
    onClick (item) {
      if (this.form.coupon_id === item.id) {
        this.$message.error('请勿添加相同关联券')
      } else {
        this.form.coupon_id = item.id
        this.couponList[0] = item
      }
    },
    addressChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
    },
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchVouchers({
          page: 1,
          size: 5,
          coupon_stock_id: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setEquity(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('equities')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getEquityDetail (id) {
      const res = await getEquityDetail({
        id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.data
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList, 0, activity.img_data)

        if (activity.coupon_id) {
          const coupon = await fetchVouchers({ coupon_id: activity.coupon_id })
          if (res.meta.code === 0) {
            this.couponList.push(coupon.data.coupons[0])
          }
        }
        if (!activity.label) {
          activity.label = []
        }

        if (!activity.show_areas) {
          activity.show_areas = []
        }
        if (!activity.hide_areas) {
          activity.hide_areas = []
        }

        this.form = activity
        this.dateTime = [activity.started_at, activity.ended_at]
        this.areaAddress = {
          province: activity.province,
          city: activity.city,
          district: activity.district
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getEquityDetail(this.$route.query.id)
    }
    this.getTabList()
  }
}
</script>

<style lang="scss" scoped>
</style>
